<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#006d53"
      ></loading>
      <div v-if="userIsRegister">
        <div class="p-3">
          <div class="text-center text-white mb-5">
            <img
              :src="userLinePictureUrl"
              alt=""
              class="rounded-full h-28 w-28 my-5 mx-auto border-4 border-white"
            />

            <div class="text-3xl text-normal font-bold">
              {{ userLineDisplayName }}
            </div>
            <div class="text-xl">คุณ{{ custInfo.CUS_FNAME }}</div>

            <button
              class="mt-5 w-full text-xl px-3 py-3 rounded-3xl shadow-lg bg-blue-900 text-white"
            >
              ยอดสะสม
              <span class="font-bold text-lg">{{ custInfo.CUS_POINT }}</span>
              บาท
            </button>
          </div>
          <div class="bg-white rounded-lg shadow-md">
            <div class="overflow-x-auto rounded-lg overflow-y-auto relative">
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
              >
                <thead>
                  <tr class="text-left text-sm">
                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      วันที่ส่งใบเสร็จ
                    </th>

                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      ประเภทใบเสร็จ
                    </th>
                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      จำนวนเงิน
                    </th>

                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      สถานะ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(h, index) in custHistory" :key="index">
                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CB_SUBMIT_DATETIME.substr(0, 10) }}<br />
                      {{ h.CB_SUBMIT_DATETIME.substr(11, 15) }}<br />
                    </td>

                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      <span v-if="h.CB_TYPE === 'GOLD'">ใบเสร็จร้านทอง</span>
                      <span v-if="h.CB_TYPE === 'OTHER'">ใบเสร็จร้านค้า</span>
                    </td>
                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap text-right"
                    >
                      {{
                        h.CB_APPROVE_STATUS === "APPROVED"
                          ? h.CB_APPROVE_BUY_AMOUNT
                          : h.CB_SUBMIT_BUY_AMOUNT
                      }}
                    </td>

                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CB_APPROVE_STATUS_TEXT }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
import router from "../router";

export default {
  name: "History",
  data() {
    return {
      header: null,
      content: null,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getCustomerInfo(this.userLineId).then(async (data) => {
        if (data.isRegisted === 1) {
          this.userIsRegister = true;
          this.custInfo = data.custInfo;
          await this.service.getBillHistory(this.userLineId).then((data) => {
            this.custHistory = data.historyData;
          });
        } else {
          this.$swal;
          router.push("/winner");
        }
        this.isLoading = false;
      });
    }
  },
};
</script>
