<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div
        class="font-bold text-2xl w-full text-center strokeme text-white pt-5"
      >
        ประกาศรายชื่อผู้โชคดี
      </div>
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#006d53"
      ></loading>
      <div class="p-3">
        <div class="">
          <div class="bg-white p-2 rounded-t-lg shadow-md">
            <img
              src="../assets/images/winner.png"
              alt=""
              class="rounded-md w-full mx-auto"
            />
          </div>

          <div class="w-full text-center">
            <div class="inline-flex w-full" role="group">
              <button
                @click="(start = 1), (end = 5)"
                type="button"
                class="w-2/12 px-2 py-3 text-sm font-medium text-gray-900 bg-extra-50 border-0 border border-white rounded-bl-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <span class="text-extra-100">VVIP</span>
                <div
                  class="text-sm bg-extra-100 text-white px-1 rounded-3xl whitespace-nowrap"
                >
                  1-5
                </div>
              </button>
              <button
                @click="(start = 6), (end = 12)"
                type="button"
                class="w-2/12 px-2 py-3 text-sm font-medium text-gray-900 bg-extra-200 border-0 border border-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <span class="text-extra-300">VVIP</span>
                <div
                  class="bg-extra-300 text-white px-1 rounded-3xl whitespace-nowrap"
                >
                  6-12
                </div>
              </button>
              <button
                @click="(start = 13), (end = 50)"
                type="button"
                class="w-2/12 px-2 py-3 text-sm font-medium text-gray-900 bg-extra-400 border-0 border border-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <span class="text-extra-500">VIP</span>
                <div
                  class="bg-extra-500 text-white px-1 rounded-3xl whitespace-nowrap"
                >
                  13-50
                </div>
              </button>
              <button
                @click="(start = 51), (end = 100)"
                type="button"
                class="w-3/12 px-2 py-3 text-sm font-medium text-gray-900 bg-extra-600 border-0 border border-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <span class="text-extra-700"> ลำดับที่</span>
                <div
                  class="bg-extra-700 text-white px-1 rounded-3xl whitespace-nowrap"
                >
                  51-100
                </div>
              </button>

              <button
                @click="(start = 101), (end = 2500)"
                type="button"
                class="w-3/12 rounded-br-lg px-2 py-3 text-sm font-medium text-gray-900 bg-extra-800 border-0 border border-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <span class="text-extra-900">ลำดับที่</span>
                <div
                  class="bg-extra-900 text-white px-1 rounded-3xl whitespace-nowrap"
                >
                  101-2,500
                </div>
              </button>
            </div>

            <div class="bg-gray-100 rounded-lg py-5 mt-2 px-4 text-center">
              <b class="text-gray-500">ค้นหาเบอร์โทรของคุณ</b>
              <div class="relative mt-2 rounded-md shadow-sm">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5"
                ></div>
                <input
                  v-model="inputMobile"
                  v-mask="'###-###-####'"
                  type="tel"
                  placeholder="เบอร์โทรของคุณ"
                  class="px-3 py-3 text-xl relative bg-blue-600 border-0 text-white rounded-lg placeholder-white outline-none w-full pl-8"
                />
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <button
                    class="text-white p-4 bg-blue-900 rounded-r-lg"
                    @click="goSearch()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="mt-2">
              <button
                @click="(start = 1), (end = 2500)"
                type="button"
                class="w-full px-4 py-2 bg-extra-500 text-sm text-white shadow font-medium text-gray-900 border-0 border-gray-900 rounded-md hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                แสดงทัั้งหมด
              </button>
            </div> -->
          </div>
          <div class="text-center text-white text-xl font-bold mt-5">
            รายชื่อผู้โชคดีลำดับที่ {{ start }} ถึง {{ end }}
          </div>
          <div class="py-3">
            <div class="overflow-x-auto overflow-y-auto relative rounded-lg">
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative rounded-lg"
              >
                <thead>
                  <tr class="text-left text-sm bg-blue-900 rounded-lg">
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      ลำดับที่
                    </th>
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      ชื่อ - นามสกุล
                    </th>
                    <!-- <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สาขา/ช่องทางที่ซื้อ
                </th> -->
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      เบอร์โทร
                    </th>

                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      ยอดเงินสะสม
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm text-gray-700">
                  <tr v-for="(h, index) in winnerList" :key="index">
                    <template v-if="h.winner_id >= start && h.winner_id <= end">
                      <td
                        class="text-sm text-left border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                      >
                        {{ h.winner_id }}.
                      </td>
                      <td
                        class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                      >
                        {{ h.winner_name.substr(0, 10) }}XXX
                      </td>
                      <!-- <td class="fz12 border-b border-gray-200 px-3 py-2">
                  {{ h.CB_SUBMIT_BUY_SHOP_NAME }}
                </td> -->
                      <td
                        class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                      >
                        {{ h.winner_mobile.substr(0, 6) }}XXXX
                      </td>
                      <td
                        class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap text-right"
                      >
                        {{ h.winner_amount }}
                      </td></template
                    >
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../data/winner.json";

function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;

  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }

    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }

  return unmaskedValue;
}
export default {
  data() {
    return {
      winnerList: json,
      inputMobile: "",
      inputMobileRaw: "",
      start: 1,
      end: 2500,
      winnerId: 0,
    };
  },
  created() {},
  methods: {
    goSearch() {
      let isError = false;
      let txtError = "";
      if (this.inputMobile === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      } else {
        this.inputMobileRaw = unmask(
          this.inputMobile,
          "###-###-####"
        ).toString();
        if (this.inputMobileRaw.length != 10) {
          isError = true;
          txtError += "<li>เบอร์มือถือไม่ถูกต้อง</li>";
        }
      }

      if (!isError) {
        this.winnerList.forEach((e) => {
          if (e.winner_mobile === this.inputMobileRaw) {
            this.winnerId = e.winner_id;
          }
        });

        if (this.winnerId > 0) {
          this.$swal({
            title: "ยินดีด้วยค่ะ",
            html:
              "<center>คุณเป็นผู้โชคดีลำดับที่ " + this.winnerId + "</center>",

            icon: "success",
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#118241",
          });
        } else {
          this.$swal({
            html: "<center>ไม่พบเบอร์โทรศัพท์ของท่าน<br />ในรายชื่อผู้โชคดี</center>",
            title: "ขออภัยค่ะ",
            icon: "error",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "ปิด",
            cancelButtonColor: "#df0007",
          });
        }
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#df0007",
        });
      }
    },
  },
};
</script>
