<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div
        class="font-bold text-2xl w-full text-center strokeme text-white p-5"
      >
        Lotus's Anniversary 2023<br />
        The Golden Moment<br />
        Park Seo Jun<br />
        Meet & Greet in Bangkok<br />ฟินไม่พักกับพัคซอจุน
      </div>
      <div class="p-3">
        <div class="rounded-lg shadow-md">
          <img src="../assets/images/001.jpg" class="rounded-lg" /><br />
          <img src="../assets/images/002.jpg" class="rounded-lg" /><br />
          <img src="../assets/images/003.jpg" class="rounded-lg" /><br />
          <img src="../assets/images/004.jpg" class="rounded-lg" /><br />
        </div>
      </div>
    </div>
  </div>
</template>
