<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div
        class="font-bold text-2xl w-full text-center strokeme text-white p-5"
      >
        คำถามที่พบบ่อย
      </div>
      <div class="px-3">
        <div class="bg-white p-3 rounded-lg shadow-md">
          <div class="bg-white px-3 rounded-lg">
            <div class="grid divide-y divide-neutral-200 mx-auto text-sm">
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >สอบถามช่องทางการลงทะเบียน</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    สามารถ add line@Lotusaward หรือ สแกน Tent card หรือ Standee
                    ที่ร้านค้าสาขาในโลตัส
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >สอบถามใบเสร็จที่สามารถร่วมกิจกรรมได้</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ใบเสร็จร้านค้าทอง/ร้านค้าในโลตัส/Food Court
                    ไฮเปร์มาร์เก็ตทั่วประเทศ ที่เป็นใบเสร็จพิมพ์
                    ยกเว้นซุปเปอร์มาร์เก็ต ไม่มีขั้นต่ำต่อใบเสร็จรับเงิน
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >โลตัสสาขาไหนร่วมโปรโมชั่นบ้าง</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ร้านค้าทองร่วมทุกสาขาในโลตัส และร้านค้าทั่วไป เช่น Top
                    Charoen, KT Optic, MR. DIY, Watson , MK, KFC , Banana,
                    Bebephone, Cute Press, MC Jeans, BATA, B-Quick ,Dairy queen
                    ,Dunkin, Food Court ฯลฯ ไม่มีขั้นต่ำต่อใบเสร็จรับเงิน
                    ร่วมเฉพาะสาขาในไฮเปอร์มาร์เก็ต เท่านั้น
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>Food Court /
                      Lotus’s Eat/Lotus’s Eatery ร่วมมั้ยคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ร่วมได้หมดค่ะ ไม่มีขั้นต่ำต่อใบเสร็จรับเงิน
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ใบเสร็จต้องวันที่ตามวันเริ่มลงทะเบียนมั้ยคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ใบเสร็จที่สามารถใช้ลงทะเบียนได้ตั้งแต่วันที่ 16 สิงหาคม 2566
                    – 8 ตุลาคม 2566
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >เริ่มกิจกรรมวันไหน</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    สะสมใบเสร็จ 16 สิงหาคม 2566 – 8 ตุลาคม 2566
                    <br />
                    ตัดยอดสะสมใบเสร็จ 8 ตุลาคม 2566 เวลา 24.00 น.<br />

                    ประกาศผล 2,500 ที่นั่ง 11 ตุลาคม 2566 เวลา 11.00 น. ทาง
                    Line@Lotusaward
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>กิจกรรมงาน
                      Park Seo Jun Meet & Greet จะได้รับบัตรเข้าร่วมงานวันไหน
                    </span>
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    สามารถรับบัตรได้วันที่ 21 ตุลาคม 2566 ณ
                    ศูนย์ประชุมแห่งชาติสิริกิติ์ ห้อง BALLROOM 1-4 ชั้น 1
                    ตั้งแต่เวลา 10.00 น. เป็นต้นไป
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >กิจกรรมงาน Park Seo Jun Meet & Greet จัดที่ไหน และ
                      เริ่มกี่โมง </span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    วันที่ 21 ตุลาคม 2566 ณ ศูนย์ประชุมแห่งชาติสิริกิติ์
                    ห้อง BALLROOM 1-4 ชั้น 1 กิจกรรมเริ่มตั้งแต่เวลา 17.00 น.
                    เป็นต้นไป
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ในการร่วมกิจกรรมใบเสร็จต้องซื้อขั่นต่ำเท่าไหร่คะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ไม่มีขั้นต่ำ สามารถสะสมใบเสร็จได้เรื่อย ๆ
                    และรอตรวจสอบใบเสร็จภายใน 24 ชม.
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >โลตัสสาขาไหนบ้างที่ร่วมกิจกรรม</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ทุกสาขาในโลตัสไฮเปอร์มาร์เก็ตรายละเอียดเพิ่มเติมบนเว็บไซต์
                    https://corporate.lotuss.com/malls/plaza-food/540/
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>
                      แจกบัตรทั้งหมดกี่ใบ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลำดับ 1-2,500 ท่านแรกที่มียอดซื้อสะสมสูงสุดค่ะ
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>2,500
                      คนที่จะได้ร่วมงาน Meet & greet
                      เมื่อลงทะเบียนก่อนได้ก่อนเลยไหมคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ไม่ใช่ค่ะ 2,500 ท่าน จะต้องเป็นยอดซื้อสูงสุดตามลำดับที่
                    1-2,500
                  </p>
                </details>
              </div>

              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>Fan Benefit
                      มีอะไรบ้าง</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    เพิ่มเติมได้ที่เว็บไซต์
                    https://corporate.lotuss.com/malls/plaza-food/540/
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >มีการประกาศผล อย่างไรบ้างคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ยอดซื้อตาม ลำดับ 1-100 ประกาศทุกวัน เวลา 10.00 น. ทาง
                    Line@Lotusaward
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>ระบบ
                      Line@Lotusaward เข้าไปใช้แล้วมีปัญหาลงทะเบียนไม่ได้
                      ติดต่อที่ไหนคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    สามารถพิมพ์ข้อความแจ้งใน Line@Lotusaward ได้เลยค่ะ
                    จะมีแอดมินคอยดูระบบ ตอบคำถามปัญหาต่างๆ ให้ค่ะ
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ถ้าต้องการสะสมใบเสร็จเพิ่มทำยังไงคะ</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลูกค้าสามารถสะสมใบเสร็จตามวิธีเดิมผ่านใน Line@Lotusaward
                    ได้เหมือนเดิมเลยค่ะ
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >การจัดลำดับที่นั่งอย่างไร</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    จะเป็นการจัดลำดับที่นั่งเรียงตามลำดับยอดซื้อค่ะ
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ส่วนลดค่ากำเหน็จ 500 บาท ใช้อย่างไร</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลูกค้าจะต้องลงทะเบียนบนแอพลิเคชั่นของโลตัส และสมัครสมาชิก
                    จากนั้นทำการใช้แลก 5 คอยน์ เพื่อรับโค้ดส่วนลดค่ากำเหน็จ 500
                    บาท รายละเอียดขั้นตอนแลกคอยน์เพิ่มเติมได้ที่เว็บไซต์
                    https://corporate.lotuss.com/malls/plaza-food/540/
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >คอยน์ได้จากที่ไหน</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    เมื่อลูกค้าทำการซื้อของในซุปเปอร์มาร์เก็ต
                    ตอนชำระเงินให้แจ้งเบอร์โทรสมาชิกมายโลตัส เพื่อทำการสะสมคอยน์
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >โค้ดส่วนลดใช้ได้กี่ครั้ง อย่างไร ระยะเวลาเท่าไหร่
                      และมีทั้งหมดเท่าไหร่</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    1 บัญชีสมาชิกมายโลตัส สามารถใช้ได้เพียง 1 ครั้ง <br />

                    ลูกค้าต้องช้อปทองรูปพรรณมูลค่า 5,000 บาท ขึ้นไป
                    จึงจะสามารถใช้ส่วนลด มูลค่า 500 บาท <br />

                    ส่วนลด 500 บาท ใช้ได้ 1 ครั้ง และต่อ 1
                    ใบเสร็จรับเงินเท่านั้น <br />

                    ส่วนลด 500 บาท มีจำนวนจำกัด 2,000 สิทธิ ตลอดรายการเท่านั้น
                    <br />

                    ระยะเวลา 16 สิงหาคม 2566 – 8 ตุลาคม 2566
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span> ใช้แอพโลตัส
                      และโค้ดส่วนลดไม่ได้ทำอย่างไร</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ใช้แอพโลตัส และโค้ดส่วนลดไม่ได้ทำอย่างไร
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ร้านทองลดค่ากำเหน็จเท่าไหร่</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลดสูงสด 60%* เงื่อนไขอื่นๆ เป็นไปตามที่ร้านค้ากำหนด
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span>ที่นั่ง VVIP
                      คืออะไร</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    เป็นที่นั่งผู้บริหารโลตัส และ ผู้สนับสนุน
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ส่วนลดค่ากำเหน็จ 500 บาท แลกรับได้จากที่ไหน</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลูกค้าสามารถดาวน์โหลด Lotus’s App คลิกเลย
                    https://cutt.ly/49K8YdP และ ทำการเข้า Banner
                    แลกรับส่วนลดค่ากำเหน็จ หรือสอบถามกับพนักงานที่ร้านค้าทอง
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ส่วนลดค่ากำเหน็จ 500 บาท จำกัดระยะเวลาการใช้มั้ย</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    เมื่อลูกค้ากด Redeem แล้ว จะต้องใช้ภายใน 1 ชม. เท่านั้น
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ขอภาพผังที่นั่ง</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    ลูกค้าสามารถเข้าไปดูรายละเอียดเพิ่มเติมได้ที่ Website :
                    https://corporate.lotuss.com/malls/plaza-food/540/
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
              <div class="py-3">
                <details class="group">
                  <summary
                    class="flex items-center font-medium cursor-pointer list-none"
                  >
                    <span
                      ><span class="text-blue-700 font-bold"></span
                      >ส่วนลดค่ากำเหน็จ 500 บาท มีข้อจำกัดการใช้หรือไม่</span
                    >
                  </summary>
                  <p class="text-yellow-900 mt-3 group-open:animate-fadeIn">
                    เมื่อลูกค้าช้อปทองรูปพรรณมูลค่า 5,000 บาท ขึ้นไป
                    จึงจะสามารถใช้ส่วนลดค่ากำเหน็จ 500 บาท ได้
                  </p>
                </details>
              </div>
              <!-- -------------------------------------------->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
