import { createRouter, createWebHistory } from "vue-router";

import Condition from "../views/Condition.vue";
import History from "../views/History.vue";
import TopspenderVue from "@/views/Topspender.vue";
import WinnerVue from "@/views/Winner.vue";
import Faq from "@/views/Faq.vue";
import Home from "@/views/Home.vue";
import WinnerJson from "@/views/winnerJson.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    component: Home,
  },

  {
    path: "/submit",
    component: Home,
  },
  {
    path: "/success",
    component: Home,
  },

  {
    path: "/condition",
    component: Condition,
  },
  {
    path: "/FAQ",
    component: Faq,
  },
  {
    path: "/history",
    component: History,
  },

  {
    path: "/top",
    component: WinnerVue,
  },
  {
    path: "/top-by-staff",
    component: TopspenderVue,
  },
  {
    path: "/winner-QSEWAZ01",
    component: WinnerJson,
  },
  {
    path: "/winner",
    component: WinnerVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
