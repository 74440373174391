<template>
  <div
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div v-if="drawerOpen" class="z-40 fixed inset-0 transition-opacity">
      <div
        class="absolute inset-0 bg-gray-800 opacity-80"
        tabIndex="{0}"
        @click="drawerTigger"
      />
    </div>
  </div>
  <div class="bg-white">
    <img src="../assets/images/logo-lotuss.png" class="mx-auto h-20" />
  </div>
  <div
    id="footer"
    class="z-40 fixed pt-1 bottom-0 left-0 right-0 w-full flex bg-blue-700"
  >
    <div class="flex flex-grow w-1/2 items-center justify-center">
      <router-link to="/winner">
        <div class="text-center w-full">
          <div class="">
            <img
              src="../assets/images/f2.png"
              alt=""
              class="rounded-full mx-auto"
            />
          </div>
          <div class="text-sm text-white mt-0">ประกาศผล</div>
        </div>
      </router-link>
    </div>
    <div class="flex flex-grow w-1/2 items-center justify-center">
      <router-link to="/faq">
        <div class="text-center w-full">
          <div class="">
            <img
              src="../assets/images/f1.png"
              alt=""
              class="rounded-full mx-auto"
            />
          </div>
          <div class="text-sm text-white mt-0">คำถามที่พบบ่อย</div>
        </div>
      </router-link>
    </div>
    <div class="flex flex-grow w-1/2 items-center justify-center">
      <router-link to="/history">
        <div class="text-center w-full">
          <div class="">
            <img
              :src="userLinePictureUrl"
              alt=""
              class="rounded-full mx-auto border-2 border-white"
            />
          </div>
          <div class="text-sm text-white mt-1">ประวัติส่งใบเสร็จ</div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { version } from "../../package";
import liff from "@line/liff";
import Service from "../service/service";
export default {
  data() {
    return {
      drawerOpen: false,
      appVersion: version,
      isDone: false,
      userLineDisplayName: "",
      userLinePictureUrl: "",
      userIsRegister: false,
    };
  },
  mounted() {},

  async created() {
    this.service = new Service();
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const profile = await liff.getProfile();
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
    }
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
#footer img {
  width: 50px;
  height: 50px;
}
</style>
