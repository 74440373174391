<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div class="p-3">
        <div class="bg-white p-2 rounded-lg shadow-md">
          <img
            src="../assets/images/winner.png"
            alt=""
            class="rounded-md w-full mx-auto"
          />

          <div class="text-center text-sm text-gray-600 p-5">
            <span class="font-bold text-lg">กรุณารอการประกาศผลผู้โชคดี</span
            ><br />
            <span class="text-blue-900"
              >ในวันที่ 11 ตุลาคม 2566 เวลา 10.00 น. เป็นต้นไป</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
