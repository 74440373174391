<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#006d53"
      ></loading>

      <div class="p-3">
        <div class="bg-white rounded-lg shadow-md">
          <div class="p-2">
            <img
              src="../assets/images/winner.png"
              alt=""
              class="rounded-t-md w-full mx-auto"
            />
          </div>

          <div
            class="text-blue-800 font-bold text-xl px-5 pt-5 mb-1 text-center"
          >
            เช็คยอดสะสม ณ วันที่ปัจจุบัน *
          </div>
          <div class="text-black text-md pt-5 mb-3 text-center leading-tight">
            <div class="px-5 text-gray-500">
              *ยังไม่ใช่ประกาศอย่างเป็นทางการ<br />
              ผลประกาศอย่างเป็นทางการจะถูกประกาศในวันที่ 11 ตุลาคม 2566
            </div>
          </div>
          <div class="py-5">
            <div class="overflow-x-auto overflow-y-auto relative">
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
              >
                <thead>
                  <tr class="text-left text-sm bg-blue-900">
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    ></th>
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      ชื่อ - นามสกุล
                    </th>
                    <!-- <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สาขา/ช่องทางที่ซื้อ
                </th> -->
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      เบอร์โทร
                    </th>

                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      ยอดเงินสะสม
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm text-gray-700">
                  <tr v-for="(h, index) in winNoList" :key="index">
                    <td
                      class="fz12 text-left border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.INDEX }}.
                    </td>
                    <td
                      class="fz12 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CUS_FNAME }}
                    </td>
                    <!-- <td class="fz12 border-b border-gray-200 px-3 py-2">
                  {{ h.CB_SUBMIT_BUY_SHOP_NAME }}
                </td> -->
                    <td
                      class="fz12 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CUS_MOBILE }}
                    </td>
                    <td
                      class="fz12 border-b border-gray-200 px-2 py-2 whitespace-nowrap text-right"
                    >
                      {{ h.CB_TOPSPENDER_PRICE }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../service/service";
import router from "../router";
export default {
  name: "Off",
  props: ["state"],
  data() {
    return { isLoading: false, fullPage: true, txtMessage: "", winNoList: [] };
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.isLoading = true;
    await this.service.getTopSpender(120).then((rez) => {
      console.log(rez.topData);
      this.winNoList = rez.topData;
    });
    this.isLoading = false;
  },
};
</script>
